import axios from 'axios';

const getNodeInfo = async (nodeIp, timeout = 5000) => {
  const url = `http://${nodeIp}:30003`;

  const res = await axios.post(
    url,
    {
      jsonrpc: '2.0',
      method: 'getnodestate',
      params: {},
      id: 'nkn-agent',
    },
    {
      timeout,
    },
  );

  return res;
};

export default getNodeInfo;
