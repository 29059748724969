// constants import
import reactRouterConstants from 'assets/constants/reactRouterConstants';

// icons
import { DashboardOutlined } from '@ant-design/icons';
import { GoDashboard } from 'react-icons/go';

const icons = {
  GoDashboard,
  DashboardOutlined,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: 'group-dashboard',
  title: 'Dashboard',
  type: 'group',
  children: [
    {
      id: reactRouterConstants.HOME,
      title: 'Dashboard',
      type: 'item',
      url: reactRouterConstants.HOME,
      icon: icons.DashboardOutlined,
      breadcrumbs: false,
    },
  ],
};

export default dashboard;
