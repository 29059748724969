// material-ui
import { Grid, Typography, Box } from '@mui/material';

// project import
import NodesTable from './NodesTable';

// ====|| NODES - DEFAULT ||==== //

const Nodes = () => (
  <Grid container spacing={{ xs: 1, md: 2, lg: 2, sm: 2 }}>
    <Grid item xs={12}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5">Nodes Statistics</Typography>
      </Box>
    </Grid>
    <Grid item xs={12}>
      <NodesTable />
    </Grid>
  </Grid>
);

export default Nodes;
