// types
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

// constants import
import localStorageConstants from 'assets/constants/localStorageConstants';

const persistTimeValue = localStorage.getItem(localStorageConstants.NODE_TIME_VALUE) || 50;
const persistTimeUnit = localStorage.getItem(localStorageConstants.NODE_TIME_UNIT) || 'year';

const persistNodes = JSON.parse(localStorage.getItem(localStorageConstants.NODES)) || [];

//

//

const startOfTime = moment.utc().startOf(persistTimeUnit);
const endOfTime = moment.utc().endOf(persistTimeUnit);

let from = moment.utc(startOfTime).subtract(persistTimeValue, persistTimeUnit);

if (persistTimeUnit === 'month') {
  from = moment.utc(startOfTime).subtract(persistTimeValue - 1, persistTimeUnit);

  if (persistTimeValue >= 12) {
    from = moment
      .utc(startOfTime)
      .subtract(Math.floor(persistTimeValue / 12), 'year')
      .subtract(persistTimeValue % 12, 'month');
  }
  from = moment.utc().year(from.year()).month(from.month()).startOf(persistTimeUnit);
}

// persist updated at
const persistUpdatedAt = localStorage.getItem(localStorageConstants.NODE_UPDATED_HOURS) || 'none';

// initial state
const initialState = {
  timeValue: persistTimeValue,
  timeUnit: persistTimeUnit,
  fromMonth: from.toISOString(),
  toMonth: endOfTime.toISOString(),
  isCustom: JSON.parse(localStorage.getItem(localStorageConstants.IS_CUSTOM_RANGE)) || false,
  updatedAtLastHours: persistUpdatedAt,
  nodes: persistNodes,
  isUpdating: false,
  setting: {},
};

const node = createSlice({
  name: 'node',
  initialState,
  reducers: {
    /*eslint-disable */
    setFromMonth(state, action) {
      state.fromMonth = action.payload.value;
    },
    setToMonth(state, action) {
      state.toMonth = action.payload.value;
    },
    setIsCustom(state, action) {
      state.isCustom = action.payload;
    },
    setTimeValue(state, action) {
      state.timeValue = action.payload;
    },
    setTimeUnit(state, action) {
      state.timeUnit = action.payload;
    },
    setUpdatedAtLastHours(state, action) {
      state.updatedAtLastHours = action.payload;
    },
    setNodes(state, action) {
      state.nodes = action.payload;
    },
    setUpdatingNode(state, action) {
      state.isUpdating = action.payload;
    },
  },
});

export default node.reducer;

export const {
  setFromMonth,
  setToMonth,
  setIsCustom,
  setTimeValue,
  setTimeUnit,
  setUpdatedAtLastHours,
  setNodes,
  setUpdatingNode,
} = node.actions;
