import axios from 'axios';

const networkNodeApi = {
  async getGeoNodeSummary() {
    return axios.get('https://api.nkn.org/v1/geo/summary');
  },
  async getNodeBlocks() {
    return axios.get('https://openapi.nkn.org/api/v1/blocks');
  },
  async getNodeTransactions() {
    return axios.get('https://openapi.nkn.org/api/v1/transactions');
  },
  async getNodeAddresses() {
    return axios.get('https://openapi.nkn.org/api/v1/addresses');
  },

  async getBlocksPerDay() {
    return axios.get('https://openapi.nkn.org/api/v1/statistics/daily/blocks');
  },

  async getWalletInfo(address) {
    return axios.get(`https://openapi.nkn.org/api/v1/addresses/${address}`);
  },
};

export default networkNodeApi;
