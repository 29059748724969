import React from 'react';

// @mui
import { Box } from '@mui/material';

// project imports
import Transitions from 'components/@extended/Transitions';

// icons import
import { IoIosArrowUp } from 'react-icons/io';

function ScrollTopBtn() {
  const [visible, setVisible] = React.useState(false);
  // const [hidden, setHidden] = React.useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  window.addEventListener('scroll', toggleVisible);

  React.useEffect(() => {
    const timerId = setTimeout(() => {
      setVisible(false);
    }, 4000);

    return () => clearTimeout(timerId);
  }, [visible]);

  return (
    <Transitions type="fade" in={visible}>
      <Box
        onClick={scrollToTop}
        sx={{
          position: 'fixed',
          userSelect: 'none',
          zIndex: 1000,
          width: 40,
          height: 40,
          borderRadius: 50,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'common.white',
          fontSize: 20,
          bottom: 75,
          right: 35,
          cursor: 'pointer',
          bgcolor: 'primary.main',
          boxShadow: (theme) => theme.customShadows.z1,
          opacity: 0.8,
          '&:hover': {
            opacity: 1,
          },
        }}
      >
        <IoIosArrowUp />
      </Box>
    </Transitions>
  );
}

export default ScrollTopBtn;
