/* eslint-disable */
// third-party
import CONSTANTS from 'assets/constants/localStorageConstants';
// utils
import getNodeInfo from 'utils/getNodeInfo';
import sleep from 'utils/sleep';

import { configureStore } from '@reduxjs/toolkit';
import { setNodes, setUpdatingNode } from './reducers/node';

// project import
import reducers from './reducers';
import listenerMiddleware from './listenerMiddleware';

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const store = configureStore({
  reducer: reducers,
  middleware: (
    getDefaultMiddleware, // eslint-disable-line no-use-before-define
  ) => getDefaultMiddleware({ serializableCheck: false }).prepend(listenerMiddleware.middleware),
});

const { dispatch, getState } = store;

export { store, dispatch };

// ==============================|| UPDATE PROGRESS ||============================== //

const updateNodes = async () => {
  dispatch(setUpdatingNode(true));
  const persistNodesUpdate = JSON.parse(localStorage.getItem(CONSTANTS.NODES)) || [];

  if (persistNodesUpdate.length === 0) {
    dispatch(setUpdatingNode(false));
    return;
  }
  const needUpdates = persistNodesUpdate.filter((node) => {
    const updatedAt = new Date(node.updatedAt).valueOf();

    const now = new Date().valueOf();
    const diff = now - updatedAt;
    const minutes = diff / 1000 / 60;
    return minutes > getState().setting.minutesToUpdate || node.syncState === 'UNKNOWN';
  });

  if (needUpdates.length > 0) {
    const { chunkSize } = getState().setting;

    const test = needUpdates.slice(0, chunkSize);

    const result = [];

    await Promise.allSettled(
      test.map(async (node) => {
        let updatedNode = {};
        try {
          const res = await getNodeInfo(node.ip, getState().setting.requestTimeout * 1000);

          if (res.data.result) {
            updatedNode = {
              ...node,
              ...res.data.result,
              updatedAt: new Date().toISOString(),
            };
          } else if (res?.data?.error?.code === -45022) {
            updatedNode = {
              ...node,
              syncState: 'NO_ID',
              publicKey: res?.data?.error?.publicKey,
              walletAddress: res?.data?.error?.walletAddress,
              updatedAt: new Date().toISOString(),
            };
          } else if (res?.data?.error?.code === -45024) {
            updatedNode = {
              ...node,
              syncState: 'INTERNAL_ERROR',
              updatedAt: new Date().toISOString(),
            };
          } else {
            updatedNode = {
              ...node,
              syncState: 'ERROR',
              updatedAt: new Date().toISOString(),
            };
          }
        } catch (error) {
          // console.log(error.message);

          updatedNode = {
            ...node,
            syncState: 'OFFLINE',
            updatedAt: new Date().toISOString(),
          };
        }

        result.push(updatedNode);
        // const index = persistNode.findIndex((n) => n.ip === node.ip);
        // persistNode[index] = updatedNode;
      }),
    );

    console.time('updateNodes');
    const persistNode = JSON.parse(localStorage.getItem(CONSTANTS.NODES)) || [];

    result.forEach((node) => {
      const index = persistNode.findIndex((n) => n.ip === node.ip);

      if (index !== -1) persistNode[index] = node;
    });

    store.dispatch(setNodes(persistNode));

    console.timeEnd('updateNodes');

    await sleep(1000);
  }
  dispatch(setUpdatingNode(false));
};

window.addEventListener('load', () => {
  function func1(num) {
    return new Array(num * 1024 + 1).join('a');
  }
  // Determine the size of localStorage if it's not set
  if (!localStorage.getItem('size')) {
    const backup = JSON.stringify(localStorage);
    localStorage.clear();

    var i = 0;
    try {
      // Test up to 10 MB
      for (i = 0; i <= 10000; i += 250) {
        localStorage.setItem('test', func1(i));
      }
    } catch (e) {
      localStorage.removeItem('test');
      const data = JSON.parse(backup);
      Object.keys(data).forEach(function (k) {
        localStorage.setItem(k, data[k]);
      });

      localStorage.setItem('size', i ? i - 250 : 0);
    }
  }

  const intervalId = setInterval(() => {
    try {
      // if(document.visibilityState === 'visible')
      // if (document.hasFocus())
      if (getState().node.isUpdating) {
        // console.log('isUpdating')
        return;
      } else {
        updateNodes();
      }
    } catch (error) {
      console.log(error);
      clearInterval(intervalId);
    }
  }, 5000);
});
// Initialize deferredPrompt for use later to show browser install prompt.
let deferredPrompt;

window.addEventListener('beforeinstallprompt', (e) => {
  // Prevent the mini-infobar from appearing on mobile
  e.preventDefault();
  // Stash the event so it can be triggered later.
  deferredPrompt = e;
  // Update UI notify the user they can install the PWA
  showInstallPromotion();
  // Optionally, send analytics event that PWA install promo was shown.
  console.log(`'beforeinstallprompt' event was fired.`);
});
