// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import ScrollTopBtn from 'components/ScrollTopBtn';

// toast container and css import
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// =====|| APP - THEME, ROUTER, LOCAL  ||===== //

const App = () => (
  <ThemeCustomization>
    <ScrollTop>
      <Routes />
    </ScrollTop>
    <ScrollTopBtn />
    <ToastContainer limit={2} />
  </ThemeCustomization>
);

export default App;
