// types
import { createSlice } from '@reduxjs/toolkit';

// constants import
import localStorageConstants from 'assets/constants/localStorageConstants';

// initial state
const initialState = {
  minutesToUpdate: localStorage.getItem(localStorageConstants.MINUTES_TO_UPDATE) || 30,
  chunkSize: localStorage.getItem(localStorageConstants.CHUNK_SIZE) || 50,
  requestTimeout: localStorage.getItem(localStorageConstants.REQUEST_TIMEOUT) || 15,
};

const node = createSlice({
  name: 'node',
  initialState,
  reducers: {
    /*eslint-disable */
    setMinutesToUpdate(state, action) {
      state.minutesToUpdate = action.payload;
    },
    setChunkSize(state, action) {
      state.chunkSize = action.payload;
    },
    setRequestTimeout(state, action) {
      state.requestTimeout = action.payload;
    },
  },
});

export default node.reducer;

export const { setMinutesToUpdate, setChunkSize, setRequestTimeout } = node.actions;
