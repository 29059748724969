import PropTypes from 'prop-types';

// third-party
import { motion } from 'framer-motion';

// ==============================|| ANIMATION BUTTON ||============================== //

export default function AnimateButton({ children, type }) {
  switch (type) {
    case 'rotate': // only available in paid version
    case 'slide': // only available in paid version
    case 'spining':
      return (
        <motion.div
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          whileInView={{ rotate: 360 }}
          transition={{ repeat: Infinity, duration: 1 }}
        >
          {children}
        </motion.div>
      );
    case 'flip':
      return (
        <motion.div
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          whileHover={{ rotateY: 180 }}
          transition={{ repeat: Infinity, duration: 4 }}
        >
          {children}
        </motion.div>
      );
    case 'scale': // only available in paid version
    default:
      return (
        <motion.div
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          whileHover={{ scale: 1 }}
          whileTap={{ scale: 0.9 }}
        >
          {children}
        </motion.div>
      );
  }
}

AnimateButton.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf(['slide', 'scale', 'rotate', 'spining']),
};

AnimateButton.defaultProps = {
  type: 'scale',
};
