/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

// @mui
import {
  Toolbar,
  Grid,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  TextField,
  MenuItem,
  Box,
  FormControl,
  Button,
  InputLabel,
  Select,
  Checkbox,
  ListItemText,
  Popover,
  Modal,
} from '@mui/material';

import { useSelector, useDispatch } from 'react-redux';
import { setNodes } from 'store/reducers/node';

// import icons
import { SiMicrosoftexcel } from 'react-icons/si';
import { BsFileEarmarkSpreadsheet } from 'react-icons/bs';
import { TbNetwork, TbSwitchHorizontal } from 'react-icons/tb';
import { IoAdd, IoWalletOutline } from 'react-icons/io5';
import { GrClose } from 'react-icons/gr';
import { MdDelete } from 'react-icons/md';
import { AiOutlineEdit } from 'react-icons/ai';

// project import
import MainCard from 'components/MainCard';
import DndReorderItem from 'components/DndReorderItem';
import Search from 'components/Search';
import AnimateButton from 'components/@extended/AnimateButton';

import { toast } from 'react-toastify';

// hooks import
import useResponsive from 'hooks/useResponsive';

const ITEM_HEIGHT = 80;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

// ----------------------------------------------------------------------

export default function TableToolbar({
  numSelected,
  onSearchFilter,
  selectedAttrs,
  disabledAttrs = [],
  hiddenAttrs = [],
  setSelectedAttrs,
  setOrderBy = () => {},
  setAttributes = () => {},
  attributes,
  onExportCSV = () => {},
  onExportXLSX = () => {},
  onDelete = () => {},
  onEditNodeName = () => {},
  onCopyToClipBoard,
  onCopyWalletAddress,
  searchFilterInput = '',
}) {
  const dispatch = useDispatch();

  const [showOrder, setShowOrder] = React.useState(false);
  const [showAddNodeModal, setShowAddNodeModal] = React.useState(false);

  const [ipInputValue, setIpInputValue] = React.useState('');
  const [labelInputValue, setLabelInputValue] = React.useState('');

  const [attrName, setAttrName] = React.useState([...selectedAttrs]);

  const isXs = useResponsive('down', 'md');

  const nodes = useSelector((state) => state.node);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    const newValue = typeof value === 'string' ? value.split(',') : value;
    setAttrName(newValue);
    setOrderBy('id');
    setSelectedAttrs(newValue);
  };

  const handleOpenPopOver = (event) => {
    setShowOrder(event.currentTarget);
  };
  const handleClosePopOver = () => {
    setShowOrder(null);
  };

  const handleOpenAddNodeModal = () => {
    setShowAddNodeModal(true);
  };
  const handleCloseAddNodeModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setShowAddNodeModal(false);
  };

  const handleAddNode = () => {
    try {
      if (!ipInputValue) return;

      const IP_VALIDATE =
        /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;
      const IP_EXTRACT = /\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/gm;

      const ips = ipInputValue.match(IP_EXTRACT);

      if (!ips) return toast.error('Invalid IP address');

      // console.log(ips);

      const newNodes = [...nodes.nodes];

      const uniqueIps = Array.from(new Set(ips));

      let count = 0;

      uniqueIps.forEach((ip) => {
        if (IP_VALIDATE.test(ip)) {
          if (!nodes.nodes.find((node) => node.ip === ip)) {
            const nodeInfo = {
              ip: ip,
              name: labelInputValue || '',
              height: 0,
              relayMessageCount: 0,
              proposalSubmitted: 0,
              publicKey: '',
              walletAddress: '',
              version: null,
              uptime: 0,
              syncState: 'UNKNOWN',
              updatedAt: new Date().toISOString(),
              createdAt: new Date().toISOString(),
            };

            newNodes.push(nodeInfo);
            count++;
          }
        }
      });

      // if(nodes.isUpdating)

      if (count > 0) {
        dispatch(setNodes(newNodes));
        toast.success(`${count} node(s) added successfully`);
      } else {
        toast.error('No new node found');
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setShowAddNodeModal(false);
      setIpInputValue('');
      setLabelInputValue('');
    }
  };

  const selectAbleAttrs = attributes.filter((attr) => !hiddenAttrs.includes(attr));

  return (
    <Toolbar
      sx={{
        p: isXs ? 0 : 'auto',
        mb: 2,
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <Search searchFilterInput={searchFilterInput} onChange={onSearchFilter} />
      )}

      {numSelected > 0 ? (
        <Box>
          {onCopyToClipBoard && (
            <Tooltip title="Copy IP(s) To Clip Board">
              <IconButton onClick={onCopyToClipBoard} variant="contained" color="primary">
                <TbNetwork />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Copy Wallet(s) Address">
            <IconButton onClick={onCopyWalletAddress} variant="contained" color="primary">
              <IoWalletOutline />
            </IconButton>
          </Tooltip>
          <Tooltip title="Export to CSV">
            <IconButton onClick={onExportCSV} variant="contained" color="primary">
              <BsFileEarmarkSpreadsheet />
            </IconButton>
          </Tooltip>
          <Tooltip title="Export to XLSX">
            <IconButton onClick={onExportXLSX} variant="contained" color="primary">
              <SiMicrosoftexcel />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit name">
            <IconButton onClick={onEditNodeName} variant="contained" color="primary">
              <AiOutlineEdit />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={onDelete} variant="contained" color="primary">
              <MdDelete />
            </IconButton>
          </Tooltip>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <Button
            variant="contained"
            size="small"
            onClick={handleOpenAddNodeModal}
            endIcon={<IoAdd />}
            sx={{
              height: 35.2,
            }}
          >
            Add
          </Button>
          <Modal
            open={showAddNodeModal}
            onClose={handleCloseAddNodeModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: 'absolute',
                top: '30%',
                left: '50%',
                width: {
                  lg: 650,
                  md: 600,
                  sm: 500,
                  xs: '90%',
                },
                transform: 'translate(-50%, -50%)',
              }}
            >
              <MainCard sx={{ p: 1, position: 'relative' }}>
                <IconButton
                  color="error"
                  onClick={handleCloseAddNodeModal}
                  sx={{
                    position: 'absolute',
                    right: 2,
                    top: 2,
                    '&:hover': {
                      bgcolor: (theme) => theme.palette.grey[300],
                      color: (theme) => theme.palette.common.white,
                    },
                  }}
                >
                  <GrClose />
                </IconButton>
                <form noValidate>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography align="center" variant="h5">
                        Add Node
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={ipInputValue}
                        onChange={(e) => {
                          setIpInputValue(e.target.value);
                        }}
                        multiline
                        size="small"
                        label="IP Address"
                        variant="outlined"
                        fullWidth
                        rows={6}
                        inputProps={{
                          spellCheck: false,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        value={labelInputValue}
                        onChange={(e) => {
                          setLabelInputValue(e.target.value);
                        }}
                        fullWidth
                        size="small"
                        label="Name for Nodes"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        sx={{
                          width: 90,
                        }}
                        onClick={handleAddNode}
                        size="small"
                        variant="contained"
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </MainCard>
            </Box>
          </Modal>
          &nbsp;
          <FormControl
            size="small"
            sx={{
              width: isXs ? 70 : 130,
            }}
          >
            <InputLabel id="attr-select">{`Attributes(${selectAbleAttrs.length})`}</InputLabel>
            <Select
              // labelId="attr-select"
              id="attr-select"
              MenuProps={MenuProps}
              multiple
              defaultValue={selectedAttrs}
              value={selectedAttrs}
              onChange={handleChange}
              input={<OutlinedInput label="Attributes(00)" />}
              renderValue={(selected) => `Selected(${selected.length})`}
            >
              {selectAbleAttrs.map((attr, i) => (
                <MenuItem disabled={disabledAttrs.includes(attr)} key={i} value={attr}>
                  <Checkbox checked={attrName.indexOf(attr) > -1} />
                  <ListItemText primary={attr} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          &nbsp;
          <AnimateButton>
            <Tooltip title="re-order table columns">
              <IconButton
                onClick={handleOpenPopOver}
                color={showOrder ? 'primary' : 'secondary'}
                sx={{
                  width: 35.2,
                  height: 35.2,
                  border: `1px solid  ${showOrder ? '#1890ff' : '#ccc'}`,
                  boxShadow: showOrder ? '0 0 0 2px rgb(24 144 255 / 20%)' : '',
                  '&:hover': {
                    boxShadow: showOrder ? '0 0 0 2px rgb(24 144 255 / 20%)' : '',
                    border: '1px solid #1890ff',
                  },
                }}
              >
                <TbSwitchHorizontal />
              </IconButton>
            </Tooltip>
          </AnimateButton>
          <Popover
            open={Boolean(showOrder)}
            anchorEl={showOrder}
            onClose={handleClosePopOver}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
          >
            <Box sx={{ px: 1 }}>
              <DndReorderItem
                setArr={setSelectedAttrs}
                arr={selectedAttrs}
                attributes={attributes}
                setAttributes={setAttributes}
              />
            </Box>
          </Popover>
        </Box>
      )}
    </Toolbar>
  );
}

TableToolbar.propTypes = {
  numSelected: PropTypes.number,
  searchFilter: PropTypes.string,
  onSearchFilter: PropTypes.func,
};
