const reactRouterConstants = Object.freeze({
  // main layout
  HOME: '/',
  WALLETS: '/wallets',
  NODES: '/nodes',
  SETTING: '/setting',
  // not found
  NOTFOUND: '*',
});

export default reactRouterConstants;
