import * as XLSX from 'xlsx';

const exportXLSX = (data, name) => {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');
  XLSX.writeFile(wb, `${name}-${Date.now()}.xlsx`);
};

export default exportXLSX;
