import { useState } from 'react';
// material-ui
import { Box, Button, Divider, Modal, TextField } from '@mui/material';

// project imports
import MainCard from 'components/MainCard';

const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: 300,
    lg: 400,
  },
};

function EditNameModal({ open, onClose, onSave }) {
  const [name, setName] = useState('');
  const handleSave = () => {
    onSave(name.trim());
    setName('');
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <MainCard title="Change Node(s) Name">
          <TextField
            fullWidth
            size="small"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Divider sx={{ my: 2 }} />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              sx={{
                width: 90,
              }}
              size="small"
              onClick={onClose}
              variant="outlined"
            >
              Cancel
            </Button>
            <Box sx={{ width: 20 }} />
            <Button
              sx={{
                width: 90,
              }}
              onClick={handleSave}
              size="small"
              variant="outlined"
              color="success"
            >
              Save
            </Button>
          </Box>
        </MainCard>
      </Box>
    </Modal>
  );
}

export default EditNameModal;
