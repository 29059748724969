import { lazy } from 'react';

// constants import
import reactRouterConstants from 'assets/constants/reactRouterConstants';

// project import
/*eslint-disable */
import Loadable from 'components/Loadable';
const Page404 = Loadable(lazy(() => import('pages/page404/Page404')));

// ==============================|| NOTFOUND HANDLER ROUTING ||============================== //

const NotFoundRoutes = {
  path: reactRouterConstants.NOTFOUND,
  element: <Page404 />,
};

export default NotFoundRoutes;
