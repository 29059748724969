import { useState } from 'react';
// @mui
import { Box, Tooltip, IconButton, Popover } from '@mui/material';

// icons
import { BsCalendar3 } from 'react-icons/bs';

// project import
import AnimateButton from 'components/@extended/AnimateButton';
import NodeDateRange from './NodeDateRange';
import NodeUpdatedAtFilter from './NodeUpdatedAtFilter';

function DateRangeMini() {
  const [openPopover, setOpenPopover] = useState(false);

  const handleOpenPopOver = (event) => {
    setOpenPopover(event.currentTarget);
  };
  const handleClosePopOver = () => {
    setOpenPopover(null);
  };
  return (
    <>
      <Tooltip title="Date-Range Customize">
        <IconButton
          component="span"
          disableRipple
          sx={{
            width: 28.13,
            height: 28.13,
            border: '1px solid #ccc',
            bgcolor: openPopover ? 'error.lighter' : 'grey.100',
            '&:hover': {
              bgcolor: 'primary.lighter',
            },
          }}
          aria-haspopup="true"
          onClick={handleOpenPopOver}
          color={openPopover ? 'error' : 'warning'}
        >
          <AnimateButton>
            <BsCalendar3 />
          </AnimateButton>
        </IconButton>
      </Tooltip>
      <Popover
        open={Boolean(openPopover)}
        anchorEl={openPopover}
        onClose={handleClosePopOver}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Box sx={{ px: 2.5, py: 1.8, display: 'flex' }}>
          <NodeDateRange />
          &nbsp; &nbsp;
          <NodeUpdatedAtFilter />
        </Box>
      </Popover>
    </>
  );
}

export default DateRangeMini;
