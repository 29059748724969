// third-party
import { combineReducers } from 'redux';

// project import
import menu from './menu';
import node from './node';
import wallet from './wallet';
import setting from './setting';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({ menu, node, wallet, setting });

export default reducers;
