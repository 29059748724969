import React from 'react';
import PropTypes from 'prop-types';

// lib import
import moment from 'moment';
import { startCase } from 'lodash';

// @mui
import {
  Checkbox,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Box,
  Popover,
  TextField,
  MenuItem,
  Button,
  Tooltip,
} from '@mui/material';

import { useTheme } from '@mui/styles';

// icons
import { BiFilterAlt } from 'react-icons/bi';

// project import

// ----------------------------------------------------------------------

const GRAPHQL_FILTER = [
  {
    title: 'Not null',
    value: 'notNull',
    type: ['string', 'integer'],
  },
  {
    title: 'Is null',
    value: 'null',
    type: ['string', 'integer'],
  },
  {
    title: 'Contains (case sensitive)',
    value: 'containsi',
    type: ['string'],
  },
  {
    title: 'Contains',
    value: 'contains',
    type: ['string'],
  },
  {
    title: 'Not Contains',
    value: 'notContains',
    type: ['string'],
  },
  {
    title: 'Not Contains(case sensitive)',
    value: 'notContainsi',
    type: ['string'],
  },
  {
    title: 'Equal',
    value: 'eq',
    type: ['integer', 'datetime'],
  },
  {
    title: 'Not Equal',
    value: 'ne',
    type: ['integer', 'datetime'],
  },
  {
    title: 'Greater Than',
    value: 'gt',
    type: ['integer', 'datetime'],
  },
  {
    title: 'Greater Than Or Equal',
    value: 'gte',
    type: ['integer', 'datetime'],
  },
  {
    title: 'Lesser Than',
    value: 'lt',
    type: ['integer', 'datetime'],
  },
  {
    title: 'Lesser Than Or Equal',
    value: 'lte',
    type: ['integer', 'datetime'],
  },
];

const getInputFilterType = (schemaType) => {
  switch (schemaType) {
    case 'datetime':
      return 'date';
    case 'integer':
      return 'number';
    case 'string':
      return 'text';
    default:
      return 'text';
  }
};

const getInputLength = (schemaType) => {
  switch (schemaType) {
    case 'datetime':
      return 100;
    case 'integer':
      return 50;
    case 'string':
      return 100;
    default:
      return 100;
  }
};

const getHeadCellWidth = (schemaType) => {
  switch (schemaType) {
    case 'datetime':
      return 100;
    case 'integer':
      return 60;
    case 'string':
      return 100;
    default:
      return 100;
  }
};

export default function UserListHead({
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick,
  relationAttrs = [],
  setFilter = () => {},
  filter,
  schema,
  showEnhancedFilters = true,
}) {
  const theme = useTheme();

  const [filterName, setFilterName] = React.useState('');
  const [inputFilter, setInputFilter] = React.useState(undefined);
  const [open, setOpen] = React.useState(null);
  const [curPop, setCurPop] = React.useState({});

  React.useEffect(() => {
    let value = inputFilter;
    let filterType = 'containsi';

    if (value === undefined) return;

    // console.log(schema[filterName]?.type);

    if (schema[filterName]?.type === 'integer') {
      filterType = 'gte';
      const newValue = parseInt(value, 10);

      value = newValue;
    }

    if (schema[filterName]?.type === 'datetime') {
      filterType = 'gte';
    }

    if (schema[filterName]?.type === 'boolean') {
      filterType = 'eq';
      if (value === 'true') {
        value = true;
      } else if (value === 'false') {
        value = false;
      } else {
        value = '';
      }
    }

    const timeId = setTimeout(() => {
      if (value === false || !!value) {
        setFilter((prev) => {
          if (schema[filterName]?.type === 'relation') {
            const newFilter = {
              ...prev,
              [filterName]: {
                [schema[filterName]?.filterAttr]: {
                  [filterType]: value,
                },
              },
            };
            return newFilter;
          }

          const newFilter = {
            ...prev,
            [filterName]: {
              [filterType]: value,
            },
          };

          // console.log(newFilter);

          return newFilter;
        });
      } else {
        setFilter((prev) => {
          delete prev[filterName];

          return { ...prev };
        });
      }
    }, 500);

    /* eslint-disable-next-line */
    return function () {
      clearTimeout(timeId);
    };
  }, [inputFilter]);

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const handleFilter = (e, type) => {
    if (relationAttrs.includes(e.target.dataset.name)) {
      // console.log(e.target.dataset.name);
      // console.log(e.target.value);
      // console.log(schema[e.target.dataset.name]);
    }
    setFilterName(e.target.dataset.name);

    if (type === 'datetime') {
      const time = moment(e.target.value);
      setInputFilter(time.toISOString());
      return;
    }
    setInputFilter(e.target.value.trim());
  };

  const handleOpenPopFilter = (event, attr) => {
    if (attr) {
      if (relationAttrs.includes(attr)) return;
      const prevFilter = filter[attr];

      if (!prevFilter) return;

      const prevCondition = Object.keys(prevFilter)[0];

      // console.log(prevFilter);
      setCurPop({ attr, prevCondition });
      setOpen(event.currentTarget);
    }
  };

  const handleChangePopOverFilter = (e, { props }) => {
    const { value } = props;

    // const old = filter[curPop.attr];
    // console.log(old);

    setFilter((prev) => {
      // console.log(prev);

      if (prev[curPop.attr]) {
        if (['null', 'notNull'].includes(value)) {
          prev[curPop.attr] = {
            [value]: true,
          };
        } else {
          const target = document.querySelector(`input[data-name="${curPop.attr}"]`);

          if (!target.value) return prev;
          /* eslint-disable-next-line */
          let filterValue = Object.values(prev[curPop.attr])[0];
          if (filterValue === true) {
            filterValue = target.value;
          }
          prev[curPop.attr] = {
            [value]: filterValue,
          };
        }
      }

      // console.log(prev[curPop.attr]);

      return { ...prev };
    });
  };

  const handleClearPopOverFilter = () => {
    const target = document.querySelector(`input[data-name="${curPop.attr}"]`);
    target.value = '';
    setFilter((prev) => {
      delete prev[curPop.attr];
      return { ...prev };
    });
  };

  const handleCloseFilterPopOver = () => {
    setOpen(null);
  };

  return (
    <>
      <TableHead>
        <TableRow>
          <TableCell sx={{ py: 0 }}>
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
          {headLabel.map((headCell) => (
            <TableCell
              key={headCell}
              padding="none"
              size="small"
              variant="head"
              sx={{ px: 1, py: 0 }}
            >
              <TableSortLabel
                sx={{
                  maxWidth: getHeadCellWidth(schema[headCell].type),
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
                active={orderBy === headCell}
                direction={orderBy === headCell ? order : 'asc'}
                onClick={createSortHandler(headCell)}
              >
                <Tooltip placement="right-start" title={startCase(headCell)} arrow>
                  <span
                    style={{
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    {headCell}
                  </span>
                </Tooltip>
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
        {showEnhancedFilters && (
          <TableRow>
            <TableCell></TableCell>
            {headLabel.map((headCell) => (
              <TableCell
                key={headCell}
                sx={{ px: 0.3 }}
                align="left"
                sortDirection={orderBy === headCell ? order : false}
              >
                <Box
                  sx={{
                    display: 'inline-flex',
                    width: '100%',
                    position: 'relative',
                    alignItems: 'center',
                  }}
                  title={headCell}
                >
                  <input
                    data-name={headCell}
                    style={{
                      padding: 3,
                      outline: filter[headCell]
                        ? `2px solid ${theme.palette.success.light}`
                        : 'none',
                      border: '1px solid #ccc',
                      width: '100%',
                      fontSize: '13px',
                      fontWeight: '600',
                      minWidth: getInputLength(schema[headCell].type),
                      borderRadius: '3px',
                    }}
                    onChange={(e) => {
                      handleFilter(e, schema[headCell].type);
                    }}
                    disabled={schema[headCell].type === 'datetime'}
                    type={getInputFilterType(schema[headCell].type)}
                  />
                  <Box
                    onClick={(e) => {
                      handleOpenPopFilter(e, headCell);
                    }}
                    data-name={headCell}
                    sx={{
                      borderRadius: '50%',
                      px: 1.2,
                      color: filter[headCell] ? 'success.main' : '#000',
                      cursor: 'pointer',
                      '&> svg': {
                        opacity: filter[headCell] ? 1 : 0.4,
                      },
                      '&:hover > svg': {
                        color: filter[headCell] ? 'success.main' : null,
                        opacity: filter[headCell] ? 1 : 0.4,
                      },
                    }}
                  >
                    <BiFilterAlt />
                  </Box>
                </Box>
              </TableCell>
            ))}
          </TableRow>
        )}
      </TableHead>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseFilterPopOver}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Box sx={{ minWidth: 160, p: 1 }}>
          <TextField
            id="standard-select-filter"
            size="small"
            select
            defaultValue={curPop?.prevCondition || 'eq'}
            onChange={handleChangePopOverFilter}
            sx={{
              '& .MuiInputBase-input': { py: 0.5, fontSize: '0.875rem' },
              width: '100%',
            }}
          >
            {GRAPHQL_FILTER.filter((item) => item?.type.includes(schema[curPop.attr]?.type)).map(
              (condition) => (
                <MenuItem key={condition.value} value={condition.value}>
                  {condition.title}
                </MenuItem>
              ),
            )}
          </TextField>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button size="small" sx={{ mt: 0.5, width: '100%' }} onClick={handleClearPopOverFilter}>
              Clear
            </Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
}

UserListHead.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
};
