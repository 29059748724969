import { useState, useMemo } from 'react';
import { Grid, Typography, TextField, Button, Box, Divider } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';

import { useSelector, useDispatch } from 'react-redux';
import { setMinutesToUpdate, setChunkSize, setRequestTimeout } from 'store/reducers/setting';

import { toast } from 'react-toastify';

// project import
import MainCard from 'components/MainCard';
import LocalStorage from './LocalStorage';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress sx={{ height: 15 }} variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

function LinearWithValueLabel({ progress }) {
  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel value={progress} />
    </Box>
  );
}

function Setting() {
  const dispatch = useDispatch();
  const { minutesToUpdate, chunkSize, requestTimeout } = useSelector((state) => state.setting);
  const { nodes, isUploading } = useSelector((state) => state.node);

  const [minutes, setMinutes] = useState(minutesToUpdate);
  const [size, setSize] = useState(chunkSize);
  const [timeout, setRequestTimeoutSecond] = useState(requestTimeout);

  const handleSaveChange = () => {
    if (minutes < 5 || minutes > 60) {
      toast.error('Minutes to update must be between 5 and 60');
      return;
    }

    if (size < 30 || size > 250) {
      toast.error('Update size must be between 30 and 250');
      return;
    }

    if (timeout < 5 || timeout > 60) {
      toast.error('Update request timeout must be between 5 and 60');
      return;
    }

    try {
      dispatch(setMinutesToUpdate(minutes));
      dispatch(setChunkSize(size));
      dispatch(setRequestTimeout(timeout));

      toast.success('Settings saved successfully');
    } catch (error) {
      toast.error(error.message);
    }
  };

  const updateCount = useMemo(
    () =>
      /* eslint-disable */
      nodes.filter((node) => {
        const updatedValue = new Date(node.updatedAt).valueOf();
        const now = new Date().valueOf();
        const diff = now - updatedValue;
        const m = diff / 1000 / 60;
        return m > minutesToUpdate || node.syncState === 'UNKNOWN';
      }).length,
    [nodes, minutesToUpdate, isUploading],
  );

  const progress = useMemo(() => {
    return 100 - (updateCount / nodes.length) * 100 || 100;
  }, [nodes, minutesToUpdate, isUploading]);

  return (
    <Grid container spacing={{ xs: 1, md: 2, lg: 2, sm: 2 }}>
      <Grid item xs={12} md={12} lg={12}>
        <Typography variant="h5">App Settings</Typography>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <MainCard>
          <LocalStorage />
        </MainCard>
      </Grid>
      <Grid item xs={12} md={6} lg={8}>
        <MainCard>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography variant="h5" color="text.secondary">
                Nodes Update Config
              </Typography>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <LinearWithValueLabel progress={progress} />
              <Box sx={{ display: 'flex', justifyContent: 'center', pl: 1 }}>
                <Typography variant="subtitle3" color="text.secondary">
                  Progress: {nodes.length - updateCount}/{nodes.length}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={minutes}
                onChange={(e) => setMinutes(e.target.value)}
                label="Minutes to update"
                type="number"
                fullWidth
                inputProps={{
                  min: 5,
                  max: 60,
                  step: 1,
                }}
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={size}
                onChange={(e) => setSize(e.target.value)}
                label="Update size"
                type="number"
                fullWidth
                size="small"
                inputProps={{
                  step: 1,
                  min: 30,
                  max: 250,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                value={timeout}
                min={5}
                max={60}
                onChange={(e) => setRequestTimeoutSecond(e.target.value)}
                label="Update request timeout(s)"
                type="number"
                fullWidth
                size="small"
                inputProps={{
                  step: 1,
                  min: 5,
                  max: 60,
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField disabled label="" fullWidth size="small" />
            </Grid>

            <Grid item xs={12}>
              <Button onClick={handleSaveChange} variant="contained" color="primary" fullWidth>
                Save Change
              </Button>
            </Grid>
          </Grid>
        </MainCard>
      </Grid>
    </Grid>
  );
}

export default Setting;
