import { createSlice } from '@reduxjs/toolkit';

import localStorageConstants from 'assets/constants/localStorageConstants';

const persistWallets = JSON.parse(localStorage.getItem(localStorageConstants.WALLETS)) || [];

const initialState = {
  wallets: persistWallets,
};

const wallet = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setWallets(state, action) {
      state.wallets = action.payload;
    },
  },
});

export default wallet.reducer;

export const { setWallets } = wallet.actions;
