// types
import { createSlice } from '@reduxjs/toolkit';
import reactRouterConstants from 'assets/constants/reactRouterConstants';
import localStorageConstants from 'assets/constants/localStorageConstants';

// initial state
const initialState = {
  openItem: [reactRouterConstants.HOME],
  openComponent: 'buttons',
  drawerOpen: JSON.parse(localStorage.getItem(localStorageConstants.DRAWER_OPEN)),
  componentDrawerOpen: JSON.parse(
    localStorage.getItem(localStorageConstants.COMPONENT_DRAWER_OPEN),
  ),
};

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    /*eslint-disable */
    activeItem(state, action) {
      state.openItem = action.payload.openItem;
    },

    activeComponent(state, action) {
      state.openComponent = action.payload.openComponent;
    },

    openDrawer(state, action) {
      state.drawerOpen = action.payload.drawerOpen;
    },

    openComponentDrawer(state, action) {
      state.componentDrawerOpen = action.payload.componentDrawerOpen;
    },
  },
});

export default menu.reducer;

export const { activeItem, activeComponent, openDrawer, openComponentDrawer } = menu.actions;
