const localStorageConstants = Object.freeze({
  // nodes table
  NODES_ORDER_ATTRS: 'node-order-attrs',
  NODES_ATTRS: 'node-attrs-2',
  NODES_TBL_ROWS: 'node-table-rows',
  NODES: 'nodes',
  // wallet
  WALLETS: 'wallets',
  // redux node
  NODE_TIME_UNIT: 'node-time-unit',
  NODE_TIME_VALUE: 'node-time-value',
  NODE_UPDATED_HOURS: 'node-updated-hours',

  // redux setting
  MINUTES_TO_UPDATE: 'minutes-to-update',
  CHUNK_SIZE: 'chunk-size',
  REQUEST_TIMEOUT: 'request-timeout',
  // side bar status
  DRAWER_OPEN: 'drawer-open',
  COMPONENT_DRAWER_OPEN: 'component-drawer-open',
});

export default localStorageConstants;
