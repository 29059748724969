/* eslint-disable */
import { useMemo } from 'react';
import { Box } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { useTheme } from '@mui/styles';

// project import

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 4;

const getItemStyle = (isDragging, draggableStyle, palette) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 1.4,
  margin: `0 ${grid}px 0 0`,
  borderRadius: '5px',
  // change background colour if dragging
  background: isDragging ? palette.primary.main : palette.background.paper,
  color: isDragging ? palette.common.white : palette.text.primary,
  // styles we need to apply on draggable
  ...draggableStyle,
});
const getListStyle = (isDraggingOver, palette) => ({
  background: isDraggingOver ? palette.primary.light : 'lightgrey',
  display: 'flex',
  padding: grid,
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: 'fit-content',
  marginLeft: 'auto',
  borderRadius: '5px',
});

function DndReorderItem({ arr, attributes, setAttributes }) {
  const { palette } = useTheme();

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const dragAttr = result.draggableId;

    const destinationAttr = arr[result.destination.index];

    const dragAttrIndex = attributes.findIndex((item) => item === dragAttr);
    const desAttrIndex = attributes.findIndex((item) => item === destinationAttr);

    const newOrderAttributes = reorder(attributes, dragAttrIndex, desAttrIndex);

    setAttributes(newOrderAttributes);
  };

  useMemo(() => {
    arr.sort((a, b) => attributes.indexOf(a) - attributes.indexOf(b));
  }, [attributes]);

  return (
    <>
      {arr && (
        <Box
          className="dropContext"
          sx={{
            overflow: 'scroll',
            bgcolor: '#00000000',
            py: 1,
            '&::-webkit-scrollbar': {
              lg: {
                display: 'none',
              },
              md: {
                display: 'none',
              },
            },
          }}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="horizontal">
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver, palette, arr.length)}
                  {...provided.droppableProps}
                >
                  {arr.map((element, index) => (
                    <Draggable key={element} draggableId={element} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                            palette,
                          )}
                        >
                          {element}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </Box>
      )}
    </>
  );
}

export default DndReorderItem;
