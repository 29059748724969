import React, { useState, useMemo } from 'react';

// lib import
import moment from 'moment';

// mui
import {
  Box,
  // Grid,
  // ListItemText,
  Typography,
  // ListItemButton,
  // Avatar,
  // ListItemAvatar,
  Skeleton,
  // List,
  // Popover,
} from '@mui/material';

// redux
import { useSelector } from 'react-redux';

// icons import
// import { AiFillApi } from 'react-icons/ai';
// import { FaNetworkWired } from 'react-icons/fa';
// import { SlArrowUp, SlArrowDown } from 'react-icons/sl';

// project import
import MainCard from 'components/MainCard';

// api import
// import nodeApi from 'api/nodeApi';

// utils import
// import { fNumber } from 'utils/formatNumber';

// third party
import ReactApexChart from 'react-apexcharts';

// project import
// import DateRangeMini from 'components/date-range/DateRangeMini';

const SYNC_STATE_PALETTE = [
  {
    state: 'PERSIST_FINISHED',
    color: '#00AF91',
  },
  {
    state: 'OFFLINE',
    color: '#4E8397',
  },
  {
    state: 'INTERNAL_ERROR',
    color: '#ff5149',
  },
  {
    state: 'INSTALL',
    color: '#845EC2',
  },
  {
    state: 'INSTALLED',
    color: '#D65DB1',
  },
  {
    state: 'SYNC_STARTED',
    color: '#FF6F91',
  },
  {
    state: 'WAIT_FOR_SYNCING',
    color: '#FF9671',
  },
  {
    state: 'SYNC_FINISHED',
    color: '#2C73D2',
  },
  {
    state: 'NO_ID',
    color: '#B7C4CF',
  },
  {
    state: 'UNKNOWN',
    color: '#EEE3CB',
  },
  {
    state: 'INSTALL_ERROR',
    color: '#967E76',
  },
  {
    state: 'OTHER',
    color: '#434242',
  },
];

const preState = SYNC_STATE_PALETTE.map((item) => item.state);

function NodesStatusChart() {
  const {
    fromMonth,
    toMonth,
    updatedAtLastHours,
    nodes: persistNode,
  } = useSelector((state) => state.node);

  // theme

  const [series, setSeries] = useState(null);
  const [chartLabels, setChartLabel] = useState([]);
  // const [versions, setVersions] = useState([]);
  const [stateColors, setStateColors] = useState([]);

  // const [open, setOpen] = React.useState(false);

  const timeFilter = useMemo(() => {
    const now = moment();
    if (updatedAtLastHours === 'none') return {};
    return {
      updatedAt: {
        gte: now.subtract(updatedAtLastHours, 'hour').toISOString(),
      },
    };
  }, [updatedAtLastHours]);

  // const createdAtRange = useMemo(() => {
  //   const from = moment.utc(fromMonth);
  //   const to = moment.utc(toMonth);
  //   const diff = to.diff(from, 'days');
  //   if (diff > 730) return 0;

  //   // console.log(from.toLocaleString());
  //   // console.log(to.toLocaleString());

  //   if (from.month() === to.month()) {
  //     const daysInCurrentMonth = moment.utc().month(from.month()).daysInMonth();
  //     if (diff === daysInCurrentMonth - 1) {
  //       return from.format('MMM');
  //     }
  //     // return `${from.format('DD')}-${to.format('DD/MMM')}`;
  //   }
  //   return `${diff}d`;
  // }, [fromMonth, toMonth]);

  // distinct api
  React.useEffect(() => {
    (async () => {
      try {
        // const res = await nodeApi.getDistinctSyncState();
        const stateArr = persistNode.map((node) => node.syncState);
        const stateCount = {};

        stateArr.forEach((state) => {
          stateCount[state] = (stateCount[state] || 0) + 1;
        });

        const resSyncState = Array.from(new Set(stateArr));

        // const result = await Promise.all(
        //   resSyncState.map(async (state) => {
        //     const nodeStateRes = await nodeApi.getNodesStateCount(state, {
        //       createdAt: {
        //         gte: fromMonth,
        //         lt: toMonth,
        //       },
        //       ...timeFilter,
        //     });
        //     if (nodeStateRes?.data?.nodes) {
        //       return {
        //         state,
        //         count: nodeStateRes.data.nodes.meta.pagination.total,
        //       };
        //     }
        //     return null;
        //   }),
        // );

        const result = resSyncState.map((state) => ({
          state,
          count: stateCount[state] || 0,
        }));

        const exceptionSyncStates = result.filter((status) => !preState.includes(status.state));

        const syncStatesResult = result.filter((status) => preState.includes(status.state));
        const otherSyncState = {
          state: 'OTHER',
          count: 0,
        };
        /* eslint-disable-next-line */
        for (const state of exceptionSyncStates) {
          otherSyncState.count += state.count;
        }

        if (otherSyncState.count > 0) {
          syncStatesResult.push(otherSyncState);
        }

        const colors = syncStatesResult.map(
          (status) =>
            /* eslint-disable-next-line */
            SYNC_STATE_PALETTE.find((item) => item.state === status.state)?.color || '#434242',
        );

        setStateColors(colors);
        setSeries(syncStatesResult.map((status) => status?.count));
        setChartLabel(syncStatesResult.map((status) => status?.state));
      } catch (error) {
        console.log(error);
      }
    })();
  }, [fromMonth, toMonth, timeFilter, persistNode]);

  // React.useEffect(() => {
  //   (async () => {
  //     try {
  //       const versionDistinctRes = await nodeApi.getDistinctVersion();

  //       if (versionDistinctRes?.data) {
  //         setVersions(
  //           versionDistinctRes.data
  //             .filter((version) => !['', 'null', null, undefined].includes(version))
  //             .sort()
  //             .reverse(),
  //         );
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   })();
  // }, []);

  const options = useMemo(
    () => ({
      chart: {
        width: '100%',
        animations: {
          enabled: true,
          easing: 'linear',
          speed: 500,
          animateGradually: {
            enabled: true,
            delay: 250,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 300,
          },
        },
      },
      legend: {
        position: 'bottom',
        show: true,
        showForSingleSeries: true,
        showForNullSeries: true,
        showForZeroSeries: true,
        horizontalAlign: 'left',
        onItemClick: {
          toggleDataSeries: true,
        },
        formatter(val, opts) {
          return `${val} (${opts.w.globals.series[opts.seriesIndex]})`;
        },
        fontWeight: 600,
      },
      labels: chartLabels,
      theme: {
        monochrome: {
          enabled: false,
          // color: '#000',
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            show: false,
          },
        },
      },
      dataLabels: {
        formatter(val, opts) {
          const name = opts.w.globals.labels[opts.seriesIndex];
          return [name, `${val.toFixed(1)} %`];
        },
        show: false,
        background: {
          dropShadow: {
            enable: true,
            blur: 30,
            opacity: 0.3,
            color: '#000',
          },
        },
      },
      colors: stateColors,
    }),
    [stateColors, series, chartLabels],
  );

  // const handleOpenPopOver = (event) => {
  //   setOpen(event.currentTarget);
  // };
  // const handleClosePopOver = () => {
  //   setOpen(null);
  // };
  // const handleTogglePopOver = (event) => {
  //   setOpen(open ? null : event.currentTarget);
  // };

  /*eslint-disable */
  return (
    <MainCard>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography align="left" variant="h5">
          Nodes Status
        </Typography>
        &nbsp;
        <Typography variant="h6">
          {updatedAtLastHours === 'none' ? '' : `(Last ${updatedAtLastHours}h)`}
        </Typography>
      </Box>
      {/* <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          position: 'absolute',
          top: { xs: 11, lg: 20 },
          right: { xs: 11, lg: 20 },
        }}
      >
        <DateRangeMini />
      </Box> */}
      {series ? (
        <ReactApexChart options={options} series={series} type="pie" height={350} />
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <Skeleton variant="circular" animation="wave" width={250} height={250} />
          <Skeleton animation="wave" width={'100%'} height={60} sx={{ mt: 1 }} />
        </Box>
      )}
      {/* <Box sx={{ borderTop: '1px solid #ddd', my: 2 }}></Box>
      <Grid container>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              pl: { lg: 4, xs: 1, md: 2, sm: 1 },
            }}
          >
            <ListItemButton
              disableTouchRipple
              sx={{
                pr: 0,
                pl: { xs: 0, lg: 2, md: 0, sm: 0 },
                cursor: 'default',
                '&:hover': {
                  bgcolor: '#fff',
                },
                '& .MuiListItemAvatar-root': {
                  minWidth: 55,
                },
                '& p': {
                  fontSize: '.77rem',
                },
              }}
            >
              <ListItemAvatar>
                <Avatar
                  sx={{
                    color: 'success.main',
                    bgcolor: 'success.lighter',
                  }}
                >
                  <FaNetworkWired />
                </Avatar>
              </ListItemAvatar>

              <ListItemText
                primary={
                  <Typography variant="h5">
                    {series ? fNumber(series.reduce((acc, cur) => (acc += cur), 0)) : 0}
                  </Typography>
                }
                secondary={createdAtRange ? `Total nodes (${createdAtRange})` : 'Total nodes'}
              />
            </ListItemButton>
          </Box>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              pl: { lg: 4, xs: 1, md: 2, sm: 1 },
            }}
          >
            <ListItemButton
              disableTouchRipple
              onClick={handleTogglePopOver}
              sx={{
                pr: 0,
                pl: { xs: 0, lg: 2, md: 0, sm: 0 },
                position: 'relative',
                cursor: 'pointer',
                '&:hover': {
                  bgcolor: '#ffffff00',
                },
                '& .MuiListItemAvatar-root': {
                  minWidth: 55,
                },
                '& p': {
                  fontSize: '.77rem',
                },
                '& > svg': {
                  fontSize: 12,
                  mr: 3,
                },
              }}
            >
              <ListItemAvatar>
                <Avatar
                  sx={{
                    color: 'error.main',
                    bgcolor: 'error.lighter',
                  }}
                >
                  <AiFillApi />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<Typography variant="h5">{versions?.[0]}</Typography>}
                secondary={`Version (${versions.length})`}
              />
              {open ? <SlArrowUp /> : <SlArrowDown />}
            </ListItemButton>
          </Box>
        </Grid>
      </Grid>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClosePopOver}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <List component="div" disablePadding>
          {versions.map((version) => (
            <ListItemButton key={version} sx={{ pl: 2 }}>
              <ListItemText primary={version} />
            </ListItemButton>
          ))}
        </List>
      </Popover> */}
    </MainCard>
  );
}

export default NodesStatusChart;
