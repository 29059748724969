// project import
import MainCard from 'components/MainCard';
import DateRangeMini from 'components/date-range/DateRangeMini';

// material-ui
import {
  Box,
  Typography,
  Grid,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from '@mui/material';
import { FallOutlined } from '@ant-design/icons';

// redux import
import { useDispatch } from 'react-redux';
import { activeItem } from 'store/reducers/menu';

// router component import
import { Link } from 'react-router-dom';

const WidgetItem = ({ title = 'title', value = 0, icon, color, url }) => {
  const dispatch = useDispatch();

  const handleClickRouterLink = (routerId) => {
    dispatch(activeItem({ openItem: [routerId] }));
  };

  return (
    <Link to={url} style={{ textDecoration: 'none', color: '#000' }}>
      <ListItemButton
        disableTouchRipple={!url}
        sx={{
          pr: 0,
          pl: { xs: 0, lg: 4, md: 0, sm: 0 },
          cursor: url ? 'pointer' : 'default',
          '&:hover': {
            bgcolor: url ? 'primary.lighter' : '#fff',
          },
          '& .MuiListItemAvatar-root': {
            minWidth: { xs: 49, sm: 52, md: 53, lg: 55 },
          },
          '& p': {
            fontSize: { xs: '.76rem', lg: '.78rem' },
            // whiteSpace: 'nowrap',
          },
        }}
        onClick={() => handleClickRouterLink(url)}
      >
        <ListItemAvatar>
          <Avatar
            sx={{
              color: `${color}.main`,
              bgcolor: `${color}.lighter`,
            }}
          >
            {icon}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          sx={{ textDecoration: 'none', whiteSpace: 'no-wrap' }}
          primary={
            <Typography variant="subtitle1" sx={{ fontSize: 17 }}>
              {value}
            </Typography>
          }
          secondary={title}
        />
      </ListItemButton>
    </Link>
  );
};

function DashboardWidget({ value = '0', title = 'Title', list, showTimeFilter }) {
  // console.log('render', title);
  return (
    <MainCard>
      {showTimeFilter && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'absolute',
            top: { xs: 11, lg: 15 },
            right: { xs: 11, lg: 15 },
          }}
        >
          <DateRangeMini />
        </Box>
      )}
      <Box sx={{ p: { lg: 2, md: 2, sm: 2, xs: 0 } }}>
        <Typography sx={{ fontSize: 55, fontWeight: 600, textAlign: 'center' }}>{value}</Typography>
        <Typography variant={'subtitle1'} color="success.dark" align="center">
          {title}
        </Typography>
        <Grid
          container
          columnSpacing={1}
          rowSpacing={1}
          alignItems="center"
          sx={{ borderTop: '1px solid #ccc', pt: 3, mt: 2 }}
        >
          {list.map((item, i) => (
            <Grid key={i} item xs={6} sm={6} md={6} lg={6}>
              <WidgetItem
                title={item.name}
                value={item.value}
                icon={item.icon || <FallOutlined />}
                color={item.color || 'primary'}
                url={item?.url || ''}
                highlight={item.highlight}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </MainCard>
  );
}

export default DashboardWidget;
