/* eslint-disable */

import { useState, useLayoutEffect, useMemo, useEffect } from 'react';

// lib import
import moment from 'moment';
import { toast } from 'react-toastify';

// @mui
import {
  Table,
  Paper,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Divider,
  Typography,
  TableContainer,
  Button,
  TablePagination,
  FormControlLabel,
  Box,
  Tooltip,
  Modal,
  Link,
  Badge,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// api import
// import nodeApi from 'api/nodeApi';

// hooks import
import useLocalStorage from 'hooks/useLocalStorage';

// constants import
import localStorageConstants from 'assets/constants/localStorageConstants';

// redux import
import { useSelector } from 'react-redux';

import { setNodes as setPersistNodes } from 'store/reducers/node';

// utils import
import formatISOTimeToDateFrom from 'utils/formatISOTimeToDateFrom';
import secondsToDHMS from 'utils/secondsToDHMS';
import getTypeOfValue from 'utils/getTypeOfValue';
import convertToCSV from 'utils/convertArrToCsv';
import downloadFile from 'utils/downloadFile';
import exportXLSX from 'utils/exportXLSX';

// import icons

// project import
import Loader2 from 'components/Loader2';
import Label from 'components/Label';
import MainCard from 'components/MainCard';

import TableHead from 'components/table/TableHead';
import TableToolbar from 'components/table/TableToolbar';
import NodeToolbar from './NodeToolbar';
import EditNameModal from './EditNameModal';

import InlineEdit from 'components/InlineEdit';
import { dispatch } from 'store';

// ----------------------------------------------------------------------

const nodeSchema = {
  name: {
    type: 'string',
  },
  ip: {
    type: 'string',
    required: true,
    disabled: true,
  },
  syncState: {
    type: 'string',
    disabled: true,
    required: true,
  },
  walletAddress: {
    type: 'string',
  },
  // syncStateChanged: {
  //   type: 'integer',
  //   required: true,
  //   hidden: true,
  // },
  // prevSyncState: {
  //   type: 'string',
  //   hidden: true,
  //   required: true,
  // },
  height: {
    type: 'integer',
  },
  relayMessageCount: {
    type: 'integer',
  },
  // relayHourly: {
  //   type: 'integer',
  // },
  proposalSubmitted: {
    type: 'integer',
  },
  // totalProposalSubmitted: {
  //   type: 'integer',
  // },
  publicKey: {
    type: 'string',
  },
  // nodeId: {
  //   type: 'string',
  // },
  version: {
    type: 'string',
  },
  uptime: {
    type: 'integer',
  },
  // restarted: {
  //   type: 'integer',
  // },
  createdAt: {
    type: 'datetime',
  },
  updatedAt: {
    type: 'datetime',
  },
  // installedAt: {
  //   type: 'datetime',
  // },
  // installationTimeMs: {
  //   type: 'integer',
  // },
};

const nodesAttributes = Object.keys(nodeSchema);
const requiredAttrs = [...nodesAttributes].filter((attr) => nodeSchema[attr].required);
const hiddenAttrs = [...nodesAttributes].filter((attr) => nodeSchema[attr].hidden);
const disabledAttrs = [...nodesAttributes].filter((attr) => nodeSchema[attr].disabled);

const defaultState = 'PERSIST_FINISHED';

// ----------------------------------------------------------------------

const style = {
  position: 'absolute',
  top: '30%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: 300,
    lg: 400,
  },
};

export default function NodesTable() {
  const {
    fromMonth,
    toMonth,
    updatedAtLastHours,
    nodes: perSistNode,
    isUpdating,
  } = useSelector((state) => state.node);

  const timeFilter = useMemo(() => {
    const now = moment();
    if (updatedAtLastHours === 'none') return {};
    return {
      updatedAt: {
        gte: now.subtract(updatedAtLastHours, 'hour').toISOString(),
      },
    };
  }, [updatedAtLastHours]);

  const dateRangeFilter = useMemo(
    () => ({
      createdAt: {
        gte: fromMonth,
        lt: toMonth,
      },
    }),
    [fromMonth, toMonth],
  );

  const [attributes, setAttributes] = useLocalStorage(
    localStorageConstants.NODES_ORDER_ATTRS,
    nodesAttributes,
  );

  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('ip');

  const [searchFilterInput, setSearchFilter] = useState('');

  const [rowsPerPage, setRowsPerPage] = useLocalStorage(localStorageConstants.NODES_TBL_ROWS, 5);

  const [nodes, setNodes] = useState([]);
  // const nodes = useMemo(() => {
  //   return perSistNode.slice(0, rowsPerPage);
  // }, [perSistNode, rowsPerPage]);

  const [total, setTotal] = useState(0);

  const [filter, setFilter] = useState({});

  const [syncStateFilter, setSyncStateFilter] = useState({
    syncState: {
      eq: defaultState,
    },
  });

  const [selectedAttrs, setSelectedAttrs] = useLocalStorage(
    localStorageConstants.NODES_ATTRS,
    nodesAttributes.filter((attr) => !hiddenAttrs.includes(attr)),
  );

  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [isImporting, setIsImporting] = useState(false);

  const [showEnhancedFilters, setShowEnhancedFilters] = useState(false);

  const handleExportCSV = () => {
    const exportData = nodes.filter((node) => selected.includes(node.ip));
    const dataContent = convertToCSV(exportData);
    downloadFile({ data: dataContent, fileName: `nodes-${Date.now()}.csv`, fileType: 'text/csv' });
  };

  const handleExportXLSX = () => {
    const exportData = nodes.filter((node) => selected.includes(node.ip));
    exportXLSX(exportData, 'node-sheet');
  };

  const handleCopyNodesIpToClipBoard = () => {
    const copyData = nodes
      .filter((node) => selected.includes(node.ip))
      .map((node) => node.ip)
      .join('\n');

    navigator.clipboard.writeText(copyData);
    toast.info(`Copied ${selected.length}-IP(s) to clipboard`);
  };

  const handleCopyNodesWalletAddToClipBoard = () => {
    const copyData = nodes
      .filter((node) => selected.includes(node.ip))
      .map((node) => node.walletAddress)
      .join('\n');

    if (!copyData.trim()) return toast.error('No wallet address found');

    navigator.clipboard.writeText(copyData);
    toast.info(`Copied ${selected.length}-Address(s) to clipboard`);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';

    const newOrder = isAsc ? 'desc' : 'asc';

    setOrder(newOrder);
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = nodes.map((n) => n.ip);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(+event.target.value);
  };

  const handleSearchNodesFilter = (event) => {
    const { value } = event.target;
    setSearchFilter(value);
  };

  const handleChangeNodeName = (ip, value) => {
    const newPersisNode = [...perSistNode];
    const nodeIndex = newPersisNode.findIndex((node) => node.ip === ip);
    // console.log(nodeIndex);
    // console.log(newPersisNode);
    newPersisNode[nodeIndex] = { ...newPersisNode[nodeIndex], name: value };
    dispatch(setPersistNodes(newPersisNode));
  };

  const handleShowConfirmDeleteNodeModal = () => {
    setOpen(true);
  };

  const handleShowEditNameModal = () => {
    setOpenEdit(true);
  };

  const handleDeleteNode = () => {
    const newPersisNode = perSistNode.filter((node) => !selected.includes(node.ip));
    dispatch(setPersistNodes(newPersisNode));
    toast.success(`Deleted ${selected.length} node(s)`);
    setOpen(false);
  };

  const handleChangeNodesName = (value) => {
    const newPersisNode = [...perSistNode];
    selected.forEach((ip) => {
      const nodeIndex = newPersisNode.findIndex((node) => node.ip === ip);
      newPersisNode[nodeIndex] = { ...newPersisNode[nodeIndex], name: value };
    });

    setOpenEdit(false);
    dispatch(setPersistNodes(newPersisNode));
    toast.success(`Updated ${selected.length} node(s) name`);
  };

  const handleCloseConfirmModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setOpen(false);
    setOpenEdit(false);
  };

  // handle data api
  useEffect(() => {
    let nodeFilters = { ...filter };

    if (nodeFilters.createdAt) {
      // ignore global date range filter
      nodeFilters = { ...filter, ...syncStateFilter, ...timeFilter };
    } else if (nodeFilters.syncState) {
      // ignore syncState filter
      nodeFilters = { ...filter, ...dateRangeFilter, ...timeFilter };
    } else if (nodeFilters.updatedAt) {
      // ignore syncState filter
      nodeFilters = { ...filter, ...dateRangeFilter, ...syncStateFilter };
    } else {
      nodeFilters = { ...filter, ...syncStateFilter, ...dateRangeFilter, ...timeFilter };
    }

    let orderAttr = orderBy;

    if (orderAttr === 'syncState') {
      orderAttr = 'syncStateChanged';
    }

    if (!selectedAttrs.includes(orderBy)) {
      orderAttr = 'id';
    }

    const filteredNodes = perSistNode.filter((node) => {
      const nodeAttrsValues = Object.values(node);

      const nodeAttrsValuesString = nodeAttrsValues.join(' ');

      const nodeAttrsValuesStringLowerCase = nodeAttrsValuesString.toLowerCase();

      const searchFilterLowerCase = searchFilterInput.toLowerCase();

      const isSearchFilterMatch = nodeAttrsValuesStringLowerCase.includes(searchFilterLowerCase);

      // console.log(Object.keys(nodeFilters));

      const isFilterMatch = Object.keys(nodeFilters).every((key) => {
        const filterValue = nodeFilters[key];

        // console.log(filterValue);
        // console.log(nodesAttributes);
        // console.log(key);

        return Object.keys(filterValue).every((filterKey) => {
          // console.log(filterKey);

          if (filterKey === 'eq') {
            // console.log('eq');
            return node[key] === filterValue.eq;
          }

          // if (filterKey === 'gt') {
          //   console.log('gt');
          //   return node[key] > filterValue.gt;
          // }

          if (filterKey === 'lt') {
            // console.log('lt');
            return node[key] < filterValue.lt;
          }

          // if (filterKey === 'lte') {
          //   console.log('lte');
          //   return node[key] <= filterValue.lte;
          // }

          if (filterKey === 'gte') {
            // console.log('gte');
            return node[key] >= filterValue.gte;
          }

          // return true;

          if (filterKey === 'containsi') {
            // console.log(nodeAttrsValuesStringLowerCase);
            return nodeAttrsValuesStringLowerCase.includes(filterValue.containsi.toLowerCase());
          }

          // if (filterKey === 'contains') {
          //   console.log('contains');
          //   return nodeAttrsValuesString.includes(filterValue.contains);
          // }

          // if (filterKey === 'not') {
          //   console.log('not');
          //   return !nodeAttrs.includes(filterValue.not);
          // }

          // if (filterKey === 'or') {
          //   console.log('or');
          //   return filterValue.or.some((filter) => {
          //     const filterKey = Object.keys(filter)[0];
          //     const filterValue = filter[filterKey];

          //     return (
          //       nodeAttrs.includes(filterKey) && node[filterKey].toLowerCase().includes(filterValue)
          //     );
          //   });
          // }

          // if (filterKey === 'and') {
          //   console.log('and');
          //   return filterValue.and.every((filter) => {
          //     const filterKey = Object.keys(filter)[0];
          //     const filterValue = filter[filterKey];

          //     return (
          //       nodeAttrs.includes(filterKey) && node[filterKey].toLowerCase().includes(filterValue)
          //     );
          //   });
          // }
        });
      });

      return isSearchFilterMatch && isFilterMatch;
      // return isFilterMatch;
    });

    // sort nodes
    filteredNodes.sort((a, b) => {
      const isAsc = order === 'asc';
      const aVal = a[orderAttr];
      const bVal = b[orderAttr];

      if (aVal === null && bVal === null) {
        return 0;
      }

      if (aVal === null) {
        return isAsc ? -1 : 1;
      }

      if (bVal === null) {
        return isAsc ? 1 : -1;
      }

      if (aVal < bVal) {
        return isAsc ? -1 : 1;
      }

      if (aVal > bVal) {
        return isAsc ? 1 : -1;
      }

      return 0;
    });

    // console.log(filteredNodes);

    setNodes(filteredNodes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
    setTotal(filteredNodes.length);
    // setSelected([]);
  }, [
    page,
    rowsPerPage,
    order,
    orderBy,
    filter,
    syncStateFilter,
    selectedAttrs,
    dateRangeFilter,
    timeFilter,
    searchFilterInput,
    perSistNode,
  ]);

  useEffect(() => {
    setSelected([]);
  }, [
    page,
    order,
    orderBy,
    filter,
    syncStateFilter,
    selectedAttrs,
    dateRangeFilter,
    timeFilter,
    searchFilterInput,
  ]);

  useMemo(() => {
    // sorted selected attr by attributes orders
    selectedAttrs.sort((a, b) => attributes.indexOf(a) - attributes.indexOf(b));
  }, [selectedAttrs, attributes]);

  const emptyRows = nodes && nodes.length === 0;

  const isNotFound = !!searchFilterInput && nodes.length === 0;

  return (
    <>
      <MainCard sx={{ p: 0, position: 'relative' }}>
        {isLoading && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 100,
            }}
          >
            <Loader2 progressSize={60} background={'#ffffff9c'} height={'100%'} />
          </Box>
        )}
        <TableToolbar
          numSelected={selected.length}
          searchFilter={searchFilterInput}
          // setOrderBy={setOrderBy}
          onSearchFilter={handleSearchNodesFilter}
          searchFilterInput={searchFilterInput}
          setSelectedAttrs={setSelectedAttrs}
          selectedAttrs={selectedAttrs}
          requiredAttrs={requiredAttrs}
          hiddenAttrs={hiddenAttrs}
          disabledAttrs={disabledAttrs}
          setAttributes={setAttributes}
          attributes={attributes}
          onExportCSV={handleExportCSV}
          onExportXLSX={handleExportXLSX}
          onDelete={handleShowConfirmDeleteNodeModal}
          onEditNodeName={handleShowEditNameModal}
          onCopyToClipBoard={handleCopyNodesIpToClipBoard}
          onCopyWalletAddress={handleCopyNodesWalletAddToClipBoard}
        />

        <NodeToolbar onChangeSyncStateFilter={setSyncStateFilter} defaultState={defaultState} />
        {/* <Box
          sx={{
            zIndex: 1,
            position: 'absolute',
            top: { lg: 90, xs: 130 },
            right: { lg: 0, xs: 'auto' },
            ml: { xs: 0, sm: 3 },
            mr: { lg: 3 },
          }}>
          <FormControlLabel
            control={
              <Switch
                checked={showEnhancedFilters}
                onChange={(e) => {
                  setShowEnhancedFilters(e.target.checked);
                }}
              />
            }
            label="Columns Filters"
          />
        </Box> */}

        <TableContainer sx={{ width: 'inherit' }}>
          <Table>
            <TableHead
              setFilter={setFilter}
              schema={nodeSchema}
              order={order}
              orderBy={orderBy}
              headLabel={selectedAttrs}
              rowCount={nodes.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
              filter={filter}
              showEnhancedFilters={showEnhancedFilters}
            />
            {!isNotFound && (
              <TableBody>
                {nodes.map((node) => {
                  const selectedNode = selected.indexOf(node.ip) !== -1;

                  return (
                    <TableRow
                      hover
                      key={node.ip}
                      tabIndex={-1}
                      role="checkbox"
                      selected={selectedNode}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedNode}
                          onChange={(event) => handleClick(event, node.ip)}
                        />
                      </TableCell>
                      {selectedAttrs.map((attr) => {
                        let value = node[attr];

                        if (!value) {
                          const type = getTypeOfValue(value);
                          switch (type) {
                            case 'Number':
                              break;
                            case 'String':
                              break;
                            case 'Boolean':
                              value = value.toString();
                              break;
                            default:
                              value = type;
                          }
                        }

                        if (nodeSchema[attr].type === 'datetime') {
                          value = formatISOTimeToDateFrom(node[attr]);
                        }

                        if (attr === 'uptime') {
                          value = secondsToDHMS(node[attr]);
                        }
                        if (attr === 'syncState') {
                          let color = 'warning';
                          if (value === 'INTERNAL_ERROR') color = 'error';
                          // if (value === 'INSTALL') color = 'primary';
                          if (value === 'INSTALLED') color = 'success';
                          if (value === 'SYNC_STARTED') color = 'primary';
                          // if (value === 'WAIT_FOR_SYNCING') color = 'warning';
                          // if (value === 'SYNC_FINISHED') color = 'warning';
                          if (value === 'PERSIST_FINISHED') color = 'info';

                          const changedTimes = node.syncStateChanged || 0;
                          const { prevSyncState } = node;

                          return (
                            <TableCell key={attr} align="left">
                              <Tooltip
                                arrow
                                title={prevSyncState ? `Prev "${prevSyncState}"` : null}
                              >
                                <Label sx={{ fontSize: 11 }} color={color}>
                                  {`${value}`}
                                </Label>
                              </Tooltip>
                              <Tooltip
                                title={`SyncStateChanged (${changedTimes})`}
                                placement="right"
                              >
                                <Badge badgeContent={changedTimes} color={color} max={99}>
                                  <Box sx={{ minHeight: 20 }}></Box>
                                </Badge>
                              </Tooltip>
                            </TableCell>
                          );
                        }
                        if (attr === 'ip') {
                          return (
                            <TableCell key={attr} component="th" scope="row" padding="none">
                              <Typography variant="subtitle2" noWrap>
                                <Link
                                  sx={{
                                    fontWeight: 600,
                                  }}
                                  color="text.primary"
                                  href={`http://nstatus.org/?ip=${value}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {value}
                                </Link>
                              </Typography>
                            </TableCell>
                          );
                        }

                        if (attr === 'name') {
                          return (
                            <TableCell key={value} component="th" scope="row" padding="none">
                              <InlineEdit
                                value={value}
                                id={node.ip}
                                setValue={handleChangeNodeName}
                              />
                            </TableCell>
                          );
                        }

                        return (
                          <TableCell
                            key={attr}
                            component="th"
                            scope="row"
                            padding="none"
                            align="left"
                            title={
                              nodeSchema[attr].type === 'datetime'
                                ? moment(node[attr]).local().toLocaleString()
                                : value
                            }
                          >
                            <Typography
                              variant="subtitle2"
                              noWrap
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: 80,
                              }}
                            >
                              {value}
                            </Typography>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={selectedAttrs.length + 1}>
                      <Typography align="center">No record match filter</Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}

            {isNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={selectedAttrs.length + 1} sx={{ py: 3 }}>
                    <Paper
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      <Typography variant="h3" paragraph>
                        Not found
                      </Typography>

                      <Typography variant="body2">
                        No results found for &nbsp;
                        <strong>&quot;{searchFilterInput}&quot;</strong>.
                        <br /> Try checking for typos or using complete words.
                      </Typography>
                    </Paper>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          labelRowsPerPage={''}
          SelectProps={{
            native: true,
          }}
          component="div"
          count={total}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </MainCard>
      <Modal
        open={open}
        onClose={handleCloseConfirmModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <MainCard title="Are you sure ?">
            <Typography id="modal-modal-description">
              Delete all selected nodes and all related data ?
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                sx={{
                  width: 90,
                }}
                disabled={isImporting}
                size="small"
                onClick={handleCloseConfirmModal}
                variant="outlined"
              >
                Cancel
              </Button>
              <Box sx={{ width: 20 }} />
              <LoadingButton
                sx={{
                  width: 90,
                }}
                loading={isImporting}
                onClick={handleDeleteNode}
                size="small"
                variant="outlined"
                color="error"
              >
                Delete
              </LoadingButton>
            </Box>
          </MainCard>
        </Box>
      </Modal>
      <EditNameModal
        open={openEdit}
        onClose={handleCloseConfirmModal}
        onSave={handleChangeNodesName}
      />
    </>
  );
}
