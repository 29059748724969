import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

export default function Loader2({
  width = '100%',
  height = '100%',
  progressSize = 50,
  opacity = '1',
  background = '#fff',
  text = 'NKN',
}) {
  return (
    <Box
      sx={{
        display: 'flex',
        width,
        height,
        justifyContent: 'center',
        alignItems: 'center',
        opacity,
        background,
        position: 'absolute',
        inset: '0 0 0 0',
      }}
    >
      <CircularProgress size={progressSize} color="primary" />
      <Typography
        sx={{
          position: 'absolute',
          fontSize: `${progressSize / 5}px`,
          // fontFamily: "'Roboto', sans-serif",
          fontWeight: '600',
          color: 'primary.main',
        }}
      >
        {text}
      </Typography>
    </Box>
  );
}
